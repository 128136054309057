@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

//$ligher-blue: #2F48EB;
$lighter-blue: #295DCC;
$dark-blue: #1A1D33;
$gray: #464F8A;
$light-green: #28CF70;
$dark-green: #16703D;

$intro-vertical-divider-height: 75px;
$intro-vertical-text-height: 45%;
$intro-horizontal-divider-width: 75px;

.header-img {
  //background-image: url(../img/peter.jpg);
  //background-image: url(../img/peter.jpg?inline);
}

.fake-background-image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}

.h-screen-mobile {
  height: var(--vh-full, 100vh);
  //height: 100vh;
  transition: height var(--vh-time) ease;
}

.min-h-screen-mobile {
  //min-height: 100vh;
  min-height: var(--vh-full, 100vh);
}

// Intro and extra setup
.pcontainer {
    max-width: 400px;
}


@media (min-width: 576px) {
  .pcontainer {
    max-width: 500px;
  }    
}

@media (min-width: 768px) {
  .pcontainer {
    max-width: 650px;
  }
}

@media (min-width: 992px) {
  .pcontainer {
    max-width: 850px;
  }
}

@media (min-width: 1200px) {
  .pcontainer {
    max-width: 1100px;
  }
}

@media (min-width: 1400px) {
  .pcontainer {
    max-width: 1300px;
  }
}

%paper-texture {
  @apply bg-gray-100;
  // background-image: url("pls no load http://api.thumbr.it/whitenoise-361x370.png?background=00000000&noise=D1D5DB&density=13&opacity=13");
  background-image: url("../img/paper.png");
}


@keyframes intro {
    from {
        opacity: 0.1;
        margin-top: 4vh;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}


.p-bg-gradient {
  background-image: linear-gradient(135deg, var(--tw-gradient-stops));
}

.introduction {
    width: auto;
    //background: linear-gradient(135deg, $lighter-blue, $dark-blue);
    div.pcontainer {
        height: 80vh;
        width: 100%;
        position: relative;
        
        opacity: 0.1;
        margin-top: 4vh;
        animation: intro 1s ease;
        animation-fill-mode: forwards;
    }
}

.intro-left {
    background-size: cover;
    width: 100%;
    height: 100% - $intro-vertical-text-height;
}

.intro-right {
    //margin-left: calc(50%);
    @extend %paper-texture;
    width: 100%;
    height: $intro-vertical-text-height;
    .text-spacer {
      margin-top: -0.5 * $intro-vertical-divider-height;
    }
    
}

.separator-outer {
  position: absolute;
  width: 100%;
  height: $intro-vertical-divider-height;
  margin-top: 0;
  top: calc(#{100% - $intro-vertical-text-height} - #{$intro-vertical-divider-height});
  margin-left: 0;
  filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.5));
}

.separator::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  background-color: transparent;
  clip-path: polygon(105% 0, 0 0, 0 105%);
}

.separator::after {
  position: absolute;
  content: "";
  width: 100%;
  height: $intro-vertical-divider-height;
  @apply bg-gray-100;
  //background: green;
  clip-path: polygon(105% 0, 0 105%, 100% 105%);
}

@screen lg {
  .intro-left {
    width: 45%;
  }

  .intro-right {
    width: 55%;
  }

  .separator-outer {
    width: $intro-horizontal-divider-width;
    margin-left: calc(45% - #{$intro-horizontal-divider-width});
  }
}


@screen md {
    .introduction {
        div.container {
            height: 75vh;
        }
    }
    
    .intro-left {
        background-size: cover;
        width: 50%;
        height: 100%;
      //animation: anim-intro-left 1s ease;
      //animation-fill-mode: forwards;
    }

    .intro-right {
      //margin-left: calc(50%);
      width: 50%;
      height: 100%;
      @extend %paper-texture;

      //animation: anim-intro-right 1s ease;
      //animation-fill-mode: forwards;

      div {
        margin-top: 0;
        // No widow words!
        p {
          max-width: 35rem;
        }
      }
    }

    .separator-outer {
      width: $intro-horizontal-divider-width;
      height: 100%;
      top: 0;
      margin-left: calc(50% - #{$intro-horizontal-divider-width});
    }

    .separator::before {
      position: absolute;
      content: "";
      //width: 100px;
      //height: 100%;
      background-color: transparent;
      //clip-path: polygon(100% 0, 0 0, 0 100%);
    }

    .separator::after {
      position: absolute;
      content: "";
      width: $intro-horizontal-divider-width;
      height: 100%;
      @apply bg-gray-100;
      @extend %paper-texture;
      //background: linear-gradient(to bottom right, transparent 5%, #f8fafc 5%);
      clip-path: polygon(100% 0, 0 101%, 100% 101%, 105% 101%, 105% 0);
    }

}

.extra-nav {
    li {
        @apply align-bottom;
    }
    li + li:before {
        content: "|";
        @apply ml-2 mr-2;
    }
}


.inline-link {
    @apply text-blue font-medium underline;
}

.inline-link:hover {
    @apply text-blue-light cursor-pointer;
}



.soundcloud {
    height: 75vh;
    max-width: 300px;
}

@media (min-width: 500px) {
    .soundcloud {
        max-width: 600px;
    }
}

#page {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  overscroll-behavior-y: none;
  > div {
    scroll-snap-align: start;
  }
}

#about {
  @apply bg-cover bg-no-repeat;
  background-position: 60% 00%;
  background-image: url("../img/peter2.jpg");
}

.ab-info {
  @apply opacity-0 mt-5;
  transition: opacity .5s ease, margin .5s ease, background .3s ease;
}

.ab-info.show {
  @apply opacity-100 mt-0;
}

.ab-info.no-anim {
  transition: none;
}

.no-scrollbar {
  scrollbar-color: transparent transparent;
}

.no-scrollbar * {
  scrollbar-color: auto;
}
.no-scrollbar::-webkit-scrollbar {
  height: 0px;
  pointer-events: none;
}
.no-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  pointer-events: none;
}
.no-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  pointer-events: none;
}
.no-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
}

.url-bar-height {
  height: calc(100vh - var(--vh-full));
  transition: height var(--vh-time) ease;
  content: "";
}

.no-click-highlight {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

//html {
//  font-family: "Assistant";
//  font-family: "Cantarell";
//  font-family: "Montserrat";
//  font-family: "Open Sans";
//  font-family: "Lato";
//}

.font-sans {
  @apply tracking-slight;
}

.font-display {
  @apply tracking-normal;
}